import React from 'react';
import { ACItemContainer, CheckboxIconImg } from '../Elements';
import { Box, Divider, Stack, useMediaQuery } from '@mui/material';
import CheckBoxIcon from '../../../../assets/images/Check_Box_Icon.png';
import { SubText } from '../../../../common/Elements';

const Tab1Body = ({ data }) => {
    const isMobile = useMediaQuery('(max-width:500px)');

    return (
        <Box sx={{ p: 3, border: '1px solid #8F8285', marginRight: '8px', transition: 'background-color 200ms linear;' }}>
            <Stack
                direction='column'
                spacing={4}
                marginTop={2}
                marginBottom={2}
                divider={<Divider orientation="horizontal" flexItem />}
            >
                {
                    data?.map((item, i) => (
                        <ACItemContainer key={i}>
                            {!isMobile && (
                                <CheckboxIconImg loading='lazy' src={CheckBoxIcon} alt={'checkbox'} />
                            )}
                            <SubText>{item}</SubText>
                        </ACItemContainer>
                    ))
                }
            </Stack>
        </Box>
    )
}

export default Tab1Body;