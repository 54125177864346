import { useMediaQuery } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../common/constants';
import Logo from '../Logo';
import { MenuIconBtn, MobileIcon, Nav, NavBtnLink, NavContainer, NavItem, NavMenu, NavScrollLink } from './Elements';

const NavBar = ({ toggle }) => {
    const isMobile = useMediaQuery('(max-width:960px)');
    return (
        <Nav>
            <NavContainer>
                <Link to='/' style={{ textDecoration: 'none', maxWidth: isMobile ? '150px' : '200px', paddingTop: isMobile ? '6px' : '0' }}>
                    <Logo />
                </Link>
                <MobileIcon onClick={toggle}>
                    <MenuIconBtn />
                </MobileIcon>
                <NavMenu>
                    {
                        routes.map(route => (
                            <NavItem>
                                <NavBtnLink to={route.name} smooth="true" duration={500} spy={true} exact='true' offset={-80}> {route.label} </NavBtnLink>
                            </NavItem>
                        ))
                    }
                    <NavItem>
                        <NavScrollLink to={'ContactUs'} smooth="true" duration={800} spy={true} exact='true' offset={-80}> Contact Us </NavScrollLink>
                    </NavItem>
                </NavMenu>
            </NavContainer>
        </Nav>
    )
}

export default NavBar;
