import React from 'react';
import { LinkButton2, SectionContainer } from '../../common/Elements';
import { platformCriteria } from '../../common/constants';
import { CriteriaItemsContainer, PlatformCriteriaHeder, PlatformCriteriaWrapper } from './Elements';
import Criteria from './Criteria';
import { useMediaQuery } from '@mui/material';

const PlatformCriteria = () => {
    const isMobile = useMediaQuery('(max-width:1200px)');
    function handleScroll() {
        window.scroll({
          top: document.body.offsetHeight - 10,
          left: 0, 
          behavior: 'smooth',
        });
      }
    return (
        <SectionContainer>
            <PlatformCriteriaWrapper >
                <PlatformCriteriaHeder>We are looking to partner with high-quality small and medium-sized businesses.</PlatformCriteriaHeder>
                <PlatformCriteriaHeder>We are flexible but primarily lean towards B2B services with one or more of the following characteristics.</PlatformCriteriaHeder>
                <CriteriaItemsContainer>
                    {
                        platformCriteria.map(criteria => (
                            <Criteria criteria={criteria} />
                        ))
                    }
                </CriteriaItemsContainer>
                <LinkButton2 onClick={handleScroll}
                smooth="true" duration={1000} spy={true} exact='true' offset={-80}
                style={{ marginTop: '40px', marginLeft: isMobile ? '0':'40px'}}> Contact Us </LinkButton2>
            </PlatformCriteriaWrapper>
        </SectionContainer>
    )
}

export default PlatformCriteria;
