import React, { useState } from 'react';
import Sidebar from './Sidebar';
import NavBar from './NavBar';
import { MainContainer } from '../common/Elements';
import ContactUs from './ContactUs';
import Footer from './Footer';

const BaseComponent = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNav = () => {
        setIsOpen(!isOpen);
    }

    return (
        <MainContainer>
            <NavBar toggle={toggleNav} />
            <Sidebar isOpen={isOpen} toggle={toggleNav} />
            {children}

            <ContactUs />
            <Footer />
        </MainContainer>
    )
}

export default BaseComponent;
