import React from 'react';
import { BrowserRouter as Router, Route, Routes as Switch } from 'react-router-dom';
import Team from './pages/Team';
import Home from './pages/Home';
import InvestmentCriteria from './pages/InvestmentCriteria';
import ScrollToTop from './components/ScrollToTop';

const App = () => {

    return (
        <Router>
            <ScrollToTop>
                <Switch>
                    <Route path='/' element={<Home />} exact />
                    <Route path='/team' element={<Team />} exact />
                    <Route path='/investment-criteria' element={<InvestmentCriteria />} exact />
                </Switch>
            </ScrollToTop>
        </Router>
    );
}

export default App;
