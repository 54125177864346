import React from 'react';
import { SpaceArea } from '../../common/Elements';
import PlatformCriteria from './PlatformCriteria';

const InvestmentCriteria = () => {

  return (
    <>
      <PlatformCriteria />
      <SpaceArea />
    </>
  )
}

export default InvestmentCriteria;
