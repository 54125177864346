import styled from "styled-components";
import { Div, FlexColumnContainer, MainText, SectionWrapper } from "../../common/Elements";

export const PlatformCriteriaWrapper = styled(SectionWrapper)`
    padding-top: 100px;

    @media screen and (max-width: 1200px) {
        align-items: center;
    }
`;

export const PlatformCriteriaHeder = styled(MainText)`
    text-align: left;
    font-size: 30px;
`;

export const CriteriaItemsContainer = styled(Div)`
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    margin-top: 60px;
    justify-content: center;

    @media screen and (max-width: 1200px) {
        grid-template-columns: auto auto;
    }

    @media screen and (max-width: 900px) {
        grid-template-columns: auto;
    }
`;

export const InvestmentProcessImg = styled.img`
    width: 65%;
    height: auto;

    @media screen and (max-width: 1500px) {
        width: 80%;
    }

    @media screen and (max-width: 1100px) {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        width: 120%;
    }
`;

export const CriteriaItemWrapper = styled(FlexColumnContainer)`
    padding: 30px;
    background-color: #104660;
    color: white;
    width: 300px;

    @media screen and (max-width: 500px) {
        width: auto;
    }
`; 

export const CriteriaItemHeder = styled.span`
    font-weight: 500;
    font-size: 28px;
`;

export const CriteriaListItem = styled.li`
    line-height: 2em;
    list-style-type: disc;
    list-style-position: inside;
    text-indent: -1em;
    padding-left: 1em;
`;