import React from 'react';
import { ContactUsContainer, ContactUsLabel, ContactUsWrapper, GridContainer } from './Elements';
import { Button, TextField, useMediaQuery, Grid } from '@mui/material';
import { FlexColumnCenterContainer, FlexColumnContainer } from '../../common/Elements';
import { useState } from 'react';
const ContactUs = () => {
    const isDesktop = useMediaQuery('(min-width:768px)');
    // const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    // const [hasEmailError, setHasEmailError] = useState();

    // const isValidEmail = (email) => {
    //     return /\S+@\S+\.\S+/.test(email);
    // }

    const handleChange = event => {
        const { id, value } = event.target;
        // if (id === 'email') {
        //     if (!isValidEmail(value)) {
        //         setHasEmailError(true);
        //     } else {
        //         setHasEmailError(false);
        //     }
        //     setEmail(value);
        // } else 
        if (id === 'message') {
            setMessage(value);
        } else if (id === 'subject') {
            setSubject(value)
        }
    };

    return (
        <ContactUsWrapper id='ContactUs'>
            <FlexColumnCenterContainer style={{ width: isDesktop ? '100%' : '' }}>
                <ContactUsContainer style={{ width: isDesktop ? '95%' : '' }}>
                    <ContactUsLabel>Think your company would be a good fit for Herencia? Let’s chat. </ContactUsLabel>
                    <GridContainer>
                        <FlexColumnCenterContainer>
                            {/* <LogoContainer>
                                <Logo color='black' />
                            </LogoContainer> */}
                            <div style={{width: isDesktop ? '420px' : '', position: isDesktop ? 'relative' : '', left: isDesktop ? '-56px' : '' }}>
                                <p style={{ wordWrap: 'break-word' }}>
                                    We encourage business owners and their advisors to contact us to discuss how we can work together.
                                </p>
                            </div>

                            <Grid container spacing={2}>
                                <Grid item xs={isDesktop ? 1 : 0}>
                                </Grid>
                                <Grid item xs={isDesktop ? 4 : 5}>
                                    <div>
                                        <p>Ahmed</p>
                                        <div>{'+1 (705) 772 1805'}</div>
                                        <a style={{ wordWrap: 'break-word'}} href='mailto:ahmed@herencia.partners'>ahmed@herencia.partners</a>
                                    </div>
                                </Grid>
                                <Grid item xs={isDesktop ? 1 : 0}>
                                </Grid>
                                <Grid item xs={isDesktop ? 4 : 5}>
                                    <div>
                                        <p>Nate</p>
                                        <div>{'+1 (647) 227 9843'}</div>
                                        <a style={{ wordWrap: 'break-word'}} href='mailto:nate@herencia.partners'>nate@herencia.partners</a>
                                    </div>
                                </Grid>
                            </Grid>
                        </FlexColumnCenterContainer>
                        <FlexColumnContainer style={{ gap: '0px' }}>
                            {/* <RightTopContainer>
                                <TextField
                                    required
                                    id="email"
                                    label="Email"
                                    variant="standard"
                                    type='email'
                                    value={email}
                                    error={hasEmailError}
                                    onChange={handleChange}
                                    helperText={hasEmailError === true ? "Invalid Email." : ''} />
                            </RightTopContainer> */}
                            <TextField
                                id="subject"
                                label="Subject"
                                variant="standard"
                                value={subject}
                                onChange={handleChange} />
                            <TextField
                                id="message"
                                label="Message"
                                multiline
                                rows={4}
                                variant="filled"
                                value={message}
                                onChange={handleChange}
                            />
                            <Button href={`mailto:ahmed@herencia.partners;nate@herencia.partners?subject=${subject}&body=${message}`} variant="contained" style={{ marginTop: '20px', backgroundColor: '#104660' }}>Send</Button>
                        </FlexColumnContainer>
                    </GridContainer>
                </ContactUsContainer>
            </FlexColumnCenterContainer>
        </ContactUsWrapper>
    )
}

export default ContactUs;