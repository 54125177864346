import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Tabs, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { FlexContainer, LinkButton, MainText, SectionContainer, SectionWrapper, SubText } from '../../../common/Elements';
import Tab1Body from './children/Tab1';
import { StyledTab } from './Elements';

const Section2 = () => {

    const [tabIndex, setTabIndex] = React.useState(1);
    const mediaQueryMatch = useMediaQuery('(max-width:786px)');

    // const whyHeritageDescription = `We are in it for the long term. Heritage is a hands-on partner, committed to excellence, seeking to create and grow business value over decades not years. We are people first. We recognize the best way to build enduring businesses is by empowering great employees to drive even greater customer experiences. We are honorable. We operate with transparency and integrity in everything we do.`;

    const handleChange = (event, newValue, isMobile = false) => {
        if (isMobile && (newValue === tabIndex)) {
            setTabIndex(0);
            return;
        }
        setTabIndex(newValue);
    };

    const businessOwnersData = [
        `We are committed to preserving an owners’ legacy, business culture, and customer relationships while retaining employees. Unlike private equity, we are long term oriented and growth focused, prioritizing sustainable growth over short-term profits or cost cutting.`,
        `We are a collaborative and flexible to structure transactions that makes sense for an owner and craft succession plans that helps owners transition from their day-to-day responsibilities.`
    ],
        employeesAndCommunityData = [
            `We will join the management team of the companies we partner with and become active day-to-day managers, “rolling up our sleeves” alongside existing employees.`,
            `We believe talent is our most important resource. We value investing in people, processes, and systems to create more opportunities for employees to learn, develop and grow into leaders in the business over time.`
        ],
        advisorsData = [
            `We are credible investors with proven experience, backed by seasoned investors and operators to ensure deal success and deliver on the strategic vision`,
            `We provide (1) Certainty of Execution, (2) Transparent, Fair and Honest Dialogue, (3) Efficient Closing and (4) A Secure Future for your Client`
        ];

    const accordionData = [
        {
            id: 1,
            label: 'Business Owners',
            component: <Tab1Body data={businessOwnersData} />
        },
        {
            id: 2,
            label: 'Employees and Community',
            component: <Tab1Body data={employeesAndCommunityData} />
        },
        {
            id: 3,
            label: 'Advisors',
            component: <Tab1Body data={advisorsData} />
        }
    ];


    return (
        <SectionContainer>
            <SectionWrapper>
                <MainText>Our Approach</MainText>
                <SubText style={{ marginTop: '30px' }}>We aim to create win-win solutions for all stakeholders:</SubText>
                {
                    !mediaQueryMatch &&
                    <Tabs
                        value={tabIndex}
                        onChange={handleChange}
                        variant="fullWidth"
                        TabIndicatorProps={{
                            style: {
                                display: "none",
                            },
                        }}
                        aria-label="secondary tabs example"
                    >
                        {
                            accordionData.map(data => (
                                <StyledTab value={data.id} label={data.label} />
                            ))
                        }
                    </Tabs>
                }

                {tabIndex === 1 && !mediaQueryMatch && <Tab1Body data={businessOwnersData} />}
                {tabIndex === 2 && !mediaQueryMatch && <Tab1Body data={employeesAndCommunityData} />}
                {tabIndex === 3 && !mediaQueryMatch && <Tab1Body data={advisorsData} />}
                {
                    mediaQueryMatch &&
                    accordionData.map((data, i) => (
                        <Accordion expanded={tabIndex === i + 1} onChange={e => handleChange(e, i + 1, true)} style={{ marginTop: '10px' }}>
                            <AccordionSummary style={{ backgroundColor: tabIndex === i + 1 ? '#5CDCFF' : '#F6F6F6', color: tabIndex === i + 1 ? 'white' : 'black', fontWeight: tabIndex === i + 1 ? 'bolder' : '' }}>
                                <FlexContainer style={{ justifyContent: 'space-between', width: '100%' }}>
                                    <Typography>{data.label}</Typography>
                                    {tabIndex === i + 1 ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                                </FlexContainer>
                            </AccordionSummary>
                            <AccordionDetails>
                                {data.component}
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
                <LinkButton to='/investment-criteria' smooth="true" duration={500} spy={true} exact='true' style={{ marginTop: '30px' }}>Investment Criteria </LinkButton>
            </SectionWrapper>
        </SectionContainer>
    )
}

export default Section2;
