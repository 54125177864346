import React from 'react';
import BaseComponent from '../components/BaseComponent';
import TeamComponent from '../components/Team';

const Team = () => {
  return (
    <BaseComponent>
      <TeamComponent />
    </BaseComponent>
  )
}

export default Team;
