import React from 'react';
import BaseComponent from '../components/BaseComponent';
import HomeComponent from '../components/Home';

const Home = () => {
    return (
        <BaseComponent>
            <HomeComponent />
        </BaseComponent>
    )
}

export default Home;
