import styled from "styled-components";
import { FlexContainer, Div, FlexColumnCenterContainer, SubText } from "../../common/Elements";

export const ContactUsWrapper = styled(FlexContainer)`
    justify-content: center;
    align-items: center;
    background: #EAEAEA;
    width: 100%;
`;

export const ContactUsContainer = styled(FlexColumnCenterContainer)`
    padding: 50px 30px;
    flex-direction: column;
`;

export const ContactUsLabel = styled(SubText)`
    font-size: 26px;
    font-weight: 500;
    color: #104660;
    text-align: center;
`;

export const LogoContainer = styled(Div)`
    max-width: 200px;

    @media screen and (max-width: 650px) {
        max-width: 80%;
    }
`;

export const GridContainer = styled(Div)`
    display: grid;
    grid-template-columns: auto auto;
    gap: 120px;
    margin-top: 30px;
    max-width: 1400px;
    width: 100%;

    @media screen and (max-width: 1000px) {
        gap: 70px;
    }

    @media screen and (max-width: 768px) {
        gap: 50px;
        grid-template-columns: auto;
    }
`;

export const RightTopContainer = styled(GridContainer)`
    gap: 20px;

    @media screen and (max-width: 750px) {
        grid-template-columns: auto;
    }
`;
