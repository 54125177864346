import { CloseRounded } from '@mui/icons-material';
import { Drawer } from '@mui/material';
import React from 'react'
import { routes } from '../../common/constants';
import { NavBtnLink, NavItem, NavScrollLink } from '../NavBar/Elements';
import {
    Icon,
    SidebarWrapper,
} from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {

    return (
        <Drawer anchor='right' open={isOpen} onClose={toggle}>
            <Icon onClick={toggle}>
                <CloseRounded />
            </Icon>
            <SidebarWrapper>
                {
                    routes.map(route => (
                        <NavItem>
                            <NavBtnLink onClick={toggle} to={route.name} smooth="true" duration={500} spy={true} exact='true' offset={-80}> {route.label} </NavBtnLink>
                        </NavItem>
                    ))
                }
                <NavItem>
                    <NavScrollLink onClick={toggle} to={'ContactUs'} smooth="true" duration={1000} spy={true} exact='true' offset={-80}> Contact Us </NavScrollLink>
                </NavItem>
            </SidebarWrapper>
        </Drawer>
    );
}

export default Sidebar;
