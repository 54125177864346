import React from 'react'
import { LinkButton, MainText, SectionContainer, SectionWrapper, SubHeader, SubText } from '../../../common/Elements';

const Section3 = () => {
    return (
        <SectionContainer style={{ background: '#104660', color: 'white' }}>
            <SectionWrapper>
                <MainText>Why Partner with Herencia?</MainText>
                
                <SubHeader>We are in it for the long term</SubHeader>
                <SubText>Herencia is a hands-on partner, committed to excellence, seeking to create and grow business value over decades not years.</SubText>
                
                <SubHeader>We are people first.</SubHeader>
                <SubText>We recognize the best way to build enduring businesses is by empowering great employees to drive even greater customer experiences.</SubText>
                
                <SubHeader>We are honorable.</SubHeader>
                <SubText>We operate with transparency and integrity in everything we do.</SubText>
                
                <LinkButton style={{ background: '#5CDCFF', color: '#104660', marginTop: '30px' }} to='/team' smooth="true" duration={500} spy={true} exact='true'>Meet the Team </LinkButton>
            </SectionWrapper>
        </SectionContainer>
    )
}

export default Section3;