import React from 'react';
import { CriteriaItemHeder, CriteriaListItem, CriteriaItemWrapper } from './Elements';

const Criteria = ({ criteria }) => {
    return (
        <CriteriaItemWrapper>
            <CriteriaItemHeder>{criteria.title}</CriteriaItemHeder>
            <div style={{ marginTop: '20px' }}>
                {criteria.description.map(desc => (
                    <CriteriaListItem>
                        {desc}
                    </CriteriaListItem>
                ))}
                {
                    criteria.title.toLowerCase() === 'seller'}
            </div>
        </CriteriaItemWrapper>
    )
}

export default Criteria