import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Menu, SearchRounded } from '@mui/icons-material';
import { Div } from '../../common/Elements';
import { Link as LinkS } from 'react-scroll';

export const Nav = styled(Div)`
    background: white;
    width: 100%;
    height: 100px;
    margin-top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top:0;
    z-index: 100; 
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
        height: 65px;
    }
`;

export const NavContainer = styled(Div)`
    background: white;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    padding: 0 40px;
    max-width: 1500px;
`;

export const NavLogo = styled.img`
    height: 30px;
    margin: 0 20px;
    cursor: pointer;

    @media screen and (max-width: 960px) {
        height: 25px;
    }
`;

export const MobileIcon = styled(Div)`
    display: none;

    @media screen and (max-width: 960px) {
        display: flex;
        font-size: 1.8rem;
        cursor: pointer;
    }
`;


export const MenuIconBtn = styled(Menu)`
    color: #104660;
    cursor: pointer;
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin: 0;

    @media screen and (max-width: 960px) {
        display: none;
    }
`;

export const NavItem = styled.li`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 20px;
`;

export const NavBtnLink = styled(LinkR)`
    border-radius: 50px;
    background: transparent;
    white-space: nowrap;
    color: #104660;
    font-weight: bolder;
    font-size: 18px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    position: relative;

    &:hover {
        &:after {
            transform: scaleX(1);
            transform-origin: left bottom left;
          }
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: -5px;
        left: 0;
        background-color: #ffffff;
        transform-origin: left bottom right;
        transition: transform 0.25s ease-out;
    }
`;

export const NavScrollLink = styled(LinkS)`
    border-radius: 50px;
    background: transparent;
    white-space: nowrap;
    color: #104660;
    font-weight: bolder;
    font-size: 18px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    position: relative;

    &:hover {
        &:after {
            transform: scaleX(1);
            transform-origin: left bottom left;
        }
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: -5px;
        left: 0;
        background-color: #ffffff;
        transform-origin: left bottom right;
        transition: transform 0.25s ease-out;
    }
`;

export const SearchIconBtn = styled(SearchRounded)`
    color: white;
    cursor: pointer;
    margin-top: 5px;
`;