import styled from "styled-components";
import { FlexColumnCenterContainer, FlexColumnContainer, FlexContainer } from "../../common/Elements";

const Div = styled.div``;

export const AboutUserContainer = styled(Div)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 30px;
    margin-top: 30px;

    @media screen and (max-width: 500px) {
        padding: 50px 30px;
    }
`;

export const BodyContainer = styled(Div)`
    display: grid;
    max-width: 1100px;
    grid-template-columns: auto auto;
    gap: 40px;
    padding-top: 40px;

    @media screen and (max-width: 900px) {
        grid-template-columns: auto;
        padding: 20px 0;
    }
`;

export const LeftContainer = styled(Div)`
    display: flex;
    flex-direction: column;
`;

export const ImgContainer = styled(Div)`
    width: 100%;
    min-width: 250px;
    max-width: 300px;
`;

export const DetailFirstLine = styled(Div)`
    font-size: 22px;
    line-height: 2em;
    font-weight: bold;
`;

export const MoreDetails = styled(Div)`
    font-size: 16px;
    line-height: 1.8em;
    padding-top: 20px;
    text-align: justify;
`;

export const UserDetailsContainer = styled(Div)`
    grid-column-start: 1;
    grid-column-end: 3;
    display: grid;
    background: #5CDCFF;
    grid-template-columns: 50% 50%;
    color: black;
    padding: 20px;

    @media screen and (max-width: 900px) {
        grid-column-end: 2;
    }

    @media screen and (max-width: 800px) {
        grid-template-columns: auto;
    }
`;

export const DetailItem = styled(Div)`
    margin-right: 20px;
`;

export const UserDetailsSubHeader = styled(Div)`
    font-weight: bolder;
`;

export const UserDetailsSubText = styled(Div)`
    font-size: 14px;
    padding: 12px;
`;

export const AdvisorsContainer = styled(FlexColumnCenterContainer)`
    padding: 80px 30px;

    @media screen and (max-width: 500px) {
        padding: 50px 30px;
    }
`;

export const AdvisorsWrapper = styled(FlexContainer)`
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin-top: 50px;
`;

export const AdvisorWrapper = styled(FlexColumnContainer)`
    max-width: 300px;
    padding: 20px 40px;

    @media screen and (max-width: 500px) {
        padding: 20px 0;
    }
`;

export const AdvisorImage = styled.img`
    width: 100%;
    height: 100%;
    max-width: 120px;
    border-radius: 50%;
    align-self: center;
`;

export const AdvisorName = styled.a`
    color: black;
    font-size: 24px;
    margin-top: 10px;
    align-self: center;
`;

export const AdvisorDetails = styled.span`
    margin-top: 20px;
    font-size: 15px;
    text-align: left;
    line-height: 1.9em;
`;