import { Circle } from '@mui/icons-material';
import React from 'react';
import { FlexContainer } from '../../common/Elements';
import { AdvisorDetails, AdvisorImage, AdvisorName, AdvisorWrapper } from './Elements';
import LinkedIn from '@mui/icons-material/LinkedIn';

const Advisor = ({ advisor }) => {
  return (
    <AdvisorWrapper>
      <AdvisorImage src={advisor.image} alt={advisor.name} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <a href={advisor.linkedIn} target="_blank">
          <AdvisorName>{advisor.name}</AdvisorName>
          <LinkedIn style={{ color: 'blue', position: 'relative', top: '3px' }}/>
        </a>
      </div>
      <AdvisorDetails>
        {
          advisor.details.map(detail => (
            <FlexContainer>
              <Circle style={{ fontSize: '8px', paddingTop: '10px', paddingRight: '10px' }} />
              {detail}
            </FlexContainer>
          ))
        }
      </AdvisorDetails>
    </AdvisorWrapper>
  )
}

export default Advisor