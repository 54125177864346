import AhmedImg from '../assets/images/team/ahmed.jpg';
import NateImg from '../assets/images/team/nate.jpg';
import StephenGoddard from '../assets/images/advisors/stephen_goddard.jpeg';
import SteveDivitkos from '../assets/images/advisors/steve_divitkos.jpeg';

export const routes = [
    {
        name: '/',
        label: 'Home'
    },
    {
        name: '/team',
        label: 'Team'
    },
    {
        name: '/investment-criteria',
        label: 'Investment Criteria'
    }
    // {
    //     name: '/companies`',
    //     label: 'Companies'
    // },
    // {
    //     name: '/posts',
    //     label: 'Posts'
    // }
];

export const dynamicTexts = ['Culture', 'Brand', 'Tradition', 'Talent']

export const socialLinks = [
    {
        label: 'LinkedIn',
        url: 'https://www.linkedin.com/company/herencia-partners/about/'
    },
]

export const team = [
    {
      name: 'Nate Goulbourne',
      image: NateImg,
      linkedIn: 'https://www.linkedin.com/in/nate-goulbourne/',
      position: 'Principal and Co-Founder',
      detail: 'Nate is a Principal and Co-Founder of Herencia Partners',
      moreDetails: [
        `Nate has 10-years of experience in finance, serving more recently as an investor in a variety of sectors ranging from software to consumer across North America. He brings extensive experience in business strategy, and finance leadership.`,
        `Nate has worked across many organizations including Canada Pension Plan Investment Board, Onex, and BMO Capital Markets.`,
        `Nate received an M.B.A from Harvard Business School and B.A. in Accounting from Morehouse College. He was born, raised and currently lives in Toronto, Canada.`
      ],
      userDetails: {
        biggestFailure: 'Not getting into university the first or second time that I applied. Best thing that happened to me!',
        favouriteDestination: ' Tanzania and Japan',
        favouriteBook: 'Anti-Fragile by Nassim Taleb',
        learntLesson: 'Perseverance builds character',
        grewUpIn: 'Jane and Finch, Toronto (iykyk!).',
        mostAdmiredGiantOfBusiness: `My Grandfather (Small Shop Owner). Mark Leonard (Constellation Software). Jorge Lemann (3G). Reginald Lewis (TLC Group).`,
        firstJob: `Factory worker wrapping skid for a summer!`,
      }
    },
    {
        name: 'Ahmed Dipo Alimi',
        image: AhmedImg,
        linkedIn: 'https://www.linkedin.com/in/ahmed-alimi-cfa-835ab227/',
        position: 'Principal and Co-Founder',
        detail: 'Ahmed is a Principal and Co-Founder of Herencia Partners',
        moreDetails: [
            `Ahmed has over 10-year experience in Finance and Investing, focused on investing in and supporting the growth of small and medium scale businesses in emerging markets. Through his experience, Ahmed has worked with and learnt from many founders across many sectors and supported them with solving business issues around strategy, finance, marketing, and sales.`,
            `Ahmed’s prior roles spanned many leading international organisations including KPMG, McKinsey and Helios (an investee of Helios Fairfax Partners, a TSE listed entity), the Fund for Export Development in Africa as well as FBN Capital.`,
            `Ahmed received an MBA from Harvard Business School and BSc Actuarial Science from University of Lagos, Nigeria. He is a Chartered Accountant and a CFA Charterholder. Ahmed was born and raised in Nigeria and recently migrated to Canada, where he resides with his family. He is an avid soccer fan and player and thoroughly enjoys reading and traveling.`
        ],
        userDetails: {
            biggestFailure: 'Not starting a business earlier; No school can teach leadership better than business',
            favouriteDestination: 'Lagos and Cape Town. Love the beauty of Cape and all, but nothing beats jollof rice!',
            favouriteBook: 'Originals by Adam Grant',
            learntLesson: `I’d choose an emotionally intelligent person over a smart person every time`,
            grewUpIn: 'Lagos, Nigeria.',
            mostAdmiredGiantOfBusiness: `Warren Buffett`
        }
    }
];

export const advisors = [
    {
        name: 'Stephen Goddard',
        details: [
            'Managing Director at Kamylon.',
            'Former CEO of Lifeline Ambulance Services and TransPac Aviation Academy.',
            'Currently serves on many corporate boards.'
        ],
        linkedIn: 'https://www.linkedin.com/in/stephen-goddard-4541a2172/',
        image: StephenGoddard
    },
    {
        name: 'Steve Divitkos',
        details: [
            'Founder/ CEO of Mineola Partners',
            'Former CEO of Microdea'
        ],
        linkedIn: 'https://www.linkedin.com/in/steve-divitkos-76250b2a/',
        image: SteveDivitkos
    }
];

export const platformCriteria = [
    {
        title: 'Geography',
        description: ['North America']
    },
    {
        title: 'Industry',
        description: [
            'Long-term and stable growth drivers',
            'Limited cyclicality or commodity exposure',
            'Examples include business/ industrial services, healthcare services, software etc.'
        ]
    },
    {
        title: 'Financial',
        description: [
            'Track record of stable, positive free cash flow over the last three years'
        ]
    },
    {
        title: 'Business Model',
        description: [
            'Recurring demand services and products',
            'Strong customer loyalty',
            'High value-add service and products',
            'Asset light with limited capital requirements'
        ]
    },
    {
        title: 'Team and Culture',
        description: [
            'Culture of integrity and honesty',
            'Middle level management talent'
        ]
    },
    {
        title: 'Seller',
        description: [
            'Seeking to retire or exit the business (full or majority sale)',
            'Looking to reduce their day to day involvement'
        ]
    }
];
