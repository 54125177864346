import styled from "styled-components";
import { Div, FlexContainer } from "../../../common/Elements";

export const WelcomeTitle = styled.span`
    font-size: 46px;
    line-height: 50px;
    letter-spacing: 0px;
    font-weight: 400;
`;

export const BAS1Container = styled(FlexContainer)`
    flex: 1 1 auto;
    min-height: 500px;
    align-items: center;
    padding: 0 30px;
    justify-content: flex-start;

    @media screen and (max-width: 700px) {
        justify-content: center;
    }
`;

export const FloatBox = styled(Div)`
    background-color: rgba(26,26,26,0.88);
    color: white;
    padding: 16px;
    max-width: 400px;
`;

export const BAS1TitleText = styled(WelcomeTitle)`
    font-size: 34px;
    line-height: 46px;

    @media screen and (max-width: 500px) {
        font-size: 30px;
    }
`;