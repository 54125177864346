import { useMediaQuery } from '@mui/material';
import React from 'react'
import { routes, socialLinks } from '../../common/constants';
import { FlexCenterContainer, FlexColumnContainer } from '../../common/Elements';
import Logo from '../Logo';
import {
    FooterContainer,
    FooterCopyRight,
    LinksContainer,
    FooterLink,
    FooterWrapper
} from './Elements';

const Footer = () => {
    const isMiniScreen = useMediaQuery('(max-width:350px)');

    const renderLinks = () => {
        let arr = [];
        arr = [];
        for (let i in routes) {
            const route = routes[i];
            arr.push(<FooterLink href={route.name} key={i}>{route.label}</FooterLink>);
        }
        for (let i in socialLinks) {
            const link = socialLinks[i];
            arr.push(<FooterLink href={link.url} key={i}>{link.label}</FooterLink>);
        }
        return arr;
    }

    return (
        <FlexColumnContainer style={{ width: '100%', background: 'white' }}>
            <FooterContainer>
                <FooterWrapper>
                    <div style={{ width: '205px' }}>
                        <Logo />
                        <FooterCopyRight style={{ paddingLeft: '8px', position: 'relative', top: '-25px' }}>© 2022 Herencia Partners.</FooterCopyRight>
                    </div>
                    <FlexCenterContainer>
                        <LinksContainer>
                            {
                                !isMiniScreen && renderLinks()
                            }
                            {
                                isMiniScreen && 
                                routes.map((route, i) => <FooterLink href={route.name} key={i}>{route.label}</FooterLink>)
                            }
                            {
                                isMiniScreen && 
                                socialLinks.map((link, i) => <FooterLink href={link.url} key={i}>{link.label}</FooterLink>)
                            }
                        </LinksContainer>
                    </FlexCenterContainer>
                </FooterWrapper>
            </FooterContainer>
        </FlexColumnContainer>
    )
}

export default Footer;
