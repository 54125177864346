import { useMediaQuery } from '@mui/material';
import React from 'react'
import { MainText, SectionContainer, SectionWrapper, SubText } from '../../../common/Elements';

const HomeSection2 = () => {

    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <SectionContainer>
            <SectionWrapper>
                <MainText>
                    {`Herencia Partners is focused on acquiring, operating, and growing exceptional businesses in Canada and the US.`}
                </MainText>
                <SubText style={{ padding: isMobile ? '20px 0' : '20px 50px 0 0' }}>
                    {`We seek to partner with existing owners, looking to transition out of their business and entrust the next phase of growth to honest and safe set of hands. We are committed to honoring the legacy of business owners and ensuring that the interest of their employees and customers are well served.`}
                </SubText>
            </SectionWrapper>
        </SectionContainer>
    )
}

export default HomeSection2;