import styled from "styled-components";
import { Div, FlexCenterContainer, FlexContainer } from "../../common/Elements";

export const FooterContainer = styled(FlexCenterContainer)`
    background-color: white;
    width: 100%;
`;

export const FooterWrapper = styled(FlexContainer)`
    flex: 1 1 auto;
    max-width: 1400px;
    justify-content: space-between;
    padding: 30px 40px;

    @media screen and (max-width: 700px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 20px;
    }
`

export const FooterCopyRight = styled.span`
    font-weight: 400;
    font-size: 1em;
    color: #104660;
    align-self: center;
    padding-bottom: 20px;
`;

export const LinksContainer = styled(Div)`
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 10px;
    grid-column-gap: 50px;

    @media screen and (max-width: 350px) {
        gap: 20px;
        grid-template-columns: auto;
    }
`;

export const FooterLink = styled.a`
    cursor: pointer;
    color: #104660;
    line-height: 1rem;
    padding: 5px 0;
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
`;

