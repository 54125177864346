import React from 'react';
import BaseComponent from '../components/BaseComponent';
import InvestmentCriteriaComponent from '../components/InvestmentCriteria'

const InvestmentCriteria = () => {
  return (
    <BaseComponent>
      <InvestmentCriteriaComponent />
    </BaseComponent>
  )
}

export default InvestmentCriteria;
