import React from 'react';
import '../index.css';
import LogoImg from '../assets/images/Herencia_Partners_Logo.png';

const Logo = () => {
  
  return (
    <img src={LogoImg} width={'100%'} height='auto' alt='Logo'/>
  )
}

export default Logo