import React from 'react';
import UserComponent from './UserComponent';
import { Divider } from '@mui/material';
import { SpaceArea } from '../../common/Elements';
import { team } from '../../common/constants';
import Advisors from './Advisors';

const About = () => {

  return (
    <div style={{ width: '100%'}}>
      {
        team.map((user, i) => <div key={i}>
          <UserComponent userDetails={user} index={i} />
          {
            i < team.length - 1 && <Divider style={{ margin: '0 30px' }} />
          }
        </div>)
      }
      <div style={{ backgroundColor: team.length % 2 === 0 ? 'white' : '#EBEBEB' }}>
        <Advisors />
      </div>
      <SpaceArea />
    </div>
  )
}

export default About