import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import { SectionContainer, SectionWrapper } from '../../../common/Elements';
import { FloatBox, BAS1TitleText } from './Elements';

const Section1 = () => {
    const welcomeText = `We are an Entrepreneurial Investment Firm driven by Legacy, Longevity and Trust.`

    return (
        <SectionContainer style={{ background: 'transparent', minHeight: '500px' }}>
            <SectionWrapper>
                <FloatBox>
                    <BAS1TitleText>
                        Your {<TypeAnimation
                            sequence={['Heritage', 2000, '', 10, 'Culture', 2000, '', 10, 'Brand', 2000, '', 10, 'Tradition', 2000, '', 10, 'Talent', 2000, '', 10]}
                            wrapper="span"
                            cursor={true}
                            repeat={Infinity}
                            speed={1}
                            style={{ color: '#5CDCFF' }}
                        />}.
                        <div>Our Partnership.</div>
                    </BAS1TitleText>
                    <p> {welcomeText} </p>
                </FloatBox>
            </SectionWrapper>
        </SectionContainer>
    )
}

export default Section1;
