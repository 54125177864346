import React from 'react';
import Section1 from './Section1';
import Section2 from './Section2';
import Section4 from './Section4';
import { FlexColumnContainer, SpaceArea } from '../../common/Elements';
import Section3 from './Section3';

const HomeComponent = () => {
    return (
        <FlexColumnContainer style={{ width: '100%' }}>
            <Section1 />
            <Section2 />
            <SpaceArea />
            <Section3 />
            <Section4 />
        </FlexColumnContainer>
    )
}

export default HomeComponent;
