import React from 'react'
import { advisors } from '../../common/constants';
import { MainText } from '../../common/Elements';
import Advisor from './Advisor';
import { AdvisorsContainer, AdvisorsWrapper } from './Elements';


const Advisors = () => {
    return (
        <AdvisorsContainer>
            <MainText>Advisors</MainText>
            <div style={{ backgroundColor: '#104660', height: '3px', width: '100px', marginTop: '10px' }}></div>
            <AdvisorsWrapper>
                {
                    advisors.map((advisor, i) => (
                        <div key={advisor.name}>
                            <Advisor advisor={advisor} />
                        </div>
                    ))
                }
            </AdvisorsWrapper>
        </AdvisorsContainer>
    )
}

export default Advisors;
