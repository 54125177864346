import React from 'react'
import { AboutUserContainer, BodyContainer, DetailFirstLine, DetailItem, ImgContainer, LeftContainer, MoreDetails, UserDetailsContainer, UserDetailsSubHeader, UserDetailsSubText } from './Elements';
import LinkedIn from '@mui/icons-material/LinkedIn';
import { Divider } from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';

const UserComponent = ({ userDetails, index }) => {
    const isMobile = useMediaQuery('(max-width:800px)');
    const isMinWidth900 = useMediaQuery('(min-width:900px)');

    return (
        <AboutUserContainer style={{ backgroundColor: index % 2 !== 0 ? '#EBEBEB' : 'white' }}>
            <BodyContainer>
                {
                    index % 2 !== 0 && isMinWidth900 && <LeftContainer>
                        <DetailFirstLine>{userDetails.detail}</DetailFirstLine>
                        {
                            userDetails?.moreDetails?.map((detail, i) => <MoreDetails key={i}>{detail}</MoreDetails>)
                        }
                    </LeftContainer>
                }
                <LeftContainer>
                    <ImgContainer>
                        <img src={userDetails.image} alt={userDetails.name} width='300px' height='300px' style={{ borderRadius: '50%', width: '100%' }} />
                    </ImgContainer>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '13px' }}>
                        <strong style={{ fontSize: '22px' }}>{userDetails.name}</strong> <a href={userDetails.linkedIn} rel="noreferrer" target='_blank' style={{ marginLeft: '5px', paddingTop: '5px' }}><LinkedIn style={{ color: 'blue' }} /></a>
                    </div>
                    <div style={{ fontSize: '20px' }}>{userDetails.position}</div>
                </LeftContainer>
                {
                    (index % 2 === 0 || !isMinWidth900) && <LeftContainer>
                        <DetailFirstLine>{userDetails.detail}</DetailFirstLine>
                        {
                            userDetails?.moreDetails?.map((detail, i) => <MoreDetails key={i}>{detail}</MoreDetails>)
                        }
                    </LeftContainer>
                }

                <UserDetailsContainer>
                    <DetailItem>
                        <UserDetailsSubHeader>Biggest Failure</UserDetailsSubHeader>
                        <UserDetailsSubText>{userDetails.userDetails.biggestFailure}</UserDetailsSubText>
                    </DetailItem>
                    {
                        isMobile && <Divider style={{ gridColumnStart: 1, gridColumnEnd: isMobile ? 2 : 3, background: '#C2C2C2', margin: '15px 0' }} />
                    }
                    <div style={{ marginLeft: isMobile ? '0' : '20px' }}>
                        <UserDetailsSubHeader>Favourite Destination</UserDetailsSubHeader>
                        <UserDetailsSubText>{userDetails.userDetails.favouriteDestination}</UserDetailsSubText>
                    </div>
                    <Divider style={{ gridColumnStart: 1, gridColumnEnd: isMobile ? 2 : 3, background: '#C2C2C2', margin: '15px 0' }} />
                    <DetailItem>
                        <UserDetailsSubHeader>Favourite Book</UserDetailsSubHeader>
                        <UserDetailsSubText>{userDetails.userDetails.favouriteBook}</UserDetailsSubText>
                    </DetailItem>
                    {
                        isMobile && <Divider style={{ gridColumnStart: 1, gridColumnEnd: isMobile ? 2 : 3, background: '#C2C2C2', margin: '15px 0' }} />
                    }
                    <div style={{ marginLeft: isMobile ? '0' : '20px' }}>
                        <UserDetailsSubHeader>Most Important Lessons I Learned</UserDetailsSubHeader>
                        <UserDetailsSubText>{userDetails.userDetails.learntLesson}</UserDetailsSubText>
                    </div>
                    <Divider style={{ gridColumnStart: 1, gridColumnEnd: isMobile ? 2 : 3, background: '#C2C2C2', margin: '15px 0' }} />
                    <DetailItem>
                        <UserDetailsSubHeader>Grew Up In</UserDetailsSubHeader>
                        <UserDetailsSubText>{userDetails.userDetails.grewUpIn}</UserDetailsSubText>
                    </DetailItem>
                    {
                        isMobile && <Divider style={{ gridColumnStart: 1, gridColumnEnd: isMobile ? 2 : 3, background: '#C2C2C2', margin: '15px 0' }} />
                    }
                    <DetailItem style={{ marginLeft: '20px' }}>
                        <UserDetailsSubHeader>Most admired giant of business</UserDetailsSubHeader>
                        <UserDetailsSubText>{userDetails.userDetails.mostAdmiredGiantOfBusiness}</UserDetailsSubText>
                    </DetailItem>
                </UserDetailsContainer>
            </BodyContainer>
        </AboutUserContainer>
    )
}

export default UserComponent;