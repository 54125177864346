import styled from "styled-components";
import { styled as MuiStyled } from '@mui/material/styles';
import { Div, FlexColumnCenterContainer, FlexColumnContainer, FlexContainer } from "../../../common/Elements";
import { Tab } from "@mui/material";

export const BASection2Container = styled(FlexColumnCenterContainer)`
    background: white;
    width: 100%
`;

export const BASection2Body = styled(FlexColumnContainer)`
    max-width: 1000px;
    width: 90%;
    padding: 80px 30px;

    @media screen and (max-width: 768px) {
        padding: 40px 25px;
    }
`;

export const StyledTab = MuiStyled((props) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    marginTop: '30px',
    marginRight: theme.spacing(1),
    fontSize: '17px',
    lineHeight: '28px',
    letterSpacing: '0px',
    fontWeight: 500,
    color: '#515151',
    backgroundColor: '#F6F6F6',
    transition: 'background-color 300ms linear;',
    '&.Mui-selected': {
        fontSize: '20px',
        color: '#000',
        fontWeight: 600,
        backgroundColor: '#5cdcff',
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#008AB0'
    },
}));

export const BATabsBodyText = styled.span`
    color: #808285;
    text-align: justify;
    transition: width 2s;
`;

export const ACItemContainer = styled(FlexContainer)`
    padding: 0 30px;
    align-items: center;

    @media screen and (max-width: 789px) {
        padding: 0;
    }
`;

export const CheckboxIconImg = styled.img`
    width: 40px;
    height: 40px;
    padding-right: 30px;
`;

export const Tab3Img = styled.img`
    width: 120px;
    height: 116px;
`;

export const GridContainer = styled(Div)`
    display: grid;
    grid-template-columns: auto auto;
    border: 1px solid #000000;
    position: relative;

    @media screen and (max-width: 786px) {
        grid-template-columns: auto auto;
    }

    @media screen and (max-width: 406px) {
        grid-template-columns: auto;
    }
`;

export const GridItem = styled(Div)`
    border: 1px solid #000000;
    font-size: 20px;
    padding: 20px;
`;

export const Tab4GridContainer = styled(GridContainer)`
    grid-template-columns: auto auto auto auto auto;

    @media screen and (max-width: 786px) {
        grid-template-columns: auto auto auto;
    }

    @media screen and (max-width: 560px) {
        grid-template-columns: auto auto;
    }

    @media screen and (max-width: 415px) {
        grid-template-columns: auto;
    }
`;

export const GridText = styled(Div)`
    max-width: 200px;
    color: #808285;
    text-align: center;
`;

