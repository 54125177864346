import styled from 'styled-components';

export const Icon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 12px;
    background: transparent;
    color: #104660;
    font-size: 2rem;
    cursor: pointer;
    width: 45px;
    height: 30px;
    outline: #104660 solid 1.5px;
    border-radius: 3px;
`;

export const SidebarWrapper = styled.div`
    color: #104660;
    padding-top: 45px;
    width: 100vw;
    max-width: 360px;
    background: white;
    height: 100%
`;
